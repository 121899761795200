import { useState } from "react";
import { Button } from "primereact/button";

import FormGroup from "../../../common/components/form/FormGroup";
import TicketsDropdown from "../../common/tickets/TicketsDropdown";
import { mergeTickets } from "../../common/tickets/ticketsApi";

interface TicketMergeProps {
  ticketId?: string;
  onCancel: () => void;
  onSubmit: () => void;
}

export default function TicketMerge(props: TicketMergeProps) {
  const [selectedTicketId, setSelectedTicketId] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async () => {
    if (!props.ticketId || !selectedTicketId) {
      return;
    }

    setIsLoading(true);
    await mergeTickets([props.ticketId], selectedTicketId);
    setIsLoading(false);
    props.onSubmit();
  };

  return (
    <>
      <FormGroup label="Tichet parinte">
        <TicketsDropdown value={selectedTicketId} onChange={(ticketId) => setSelectedTicketId(ticketId)}></TicketsDropdown>
      </FormGroup>
      <div className="flex justify-content-end gap-2">
        <Button label="Inapoi" text severity="secondary" onClick={props.onCancel}></Button>
        <Button label="Salveaza" icon="pi pi-save" loading={isLoading} disabled={isLoading} onClick={onSubmit}></Button>
      </div>
    </>
  );
}
