import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import { RootState } from "../../../app/store";
import { BusinessImpact } from "./BusinessImpact";

const BUSINESS_IMPACTS_API_URL = `${process.env.REACT_APP_API_BASE_URL}/common/BusinessImpacts`;

const businessImpactsAdapter = createEntityAdapter<BusinessImpact>({});

const initialState = businessImpactsAdapter.getInitialState();

const businessImpactsSlice = createSlice({
  name: "businessImpacts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBusinessImpacts.fulfilled, (state, action) => {
      businessImpactsAdapter.setAll(state, action.payload);
    });
  },
});

export const fetchBusinessImpacts = createAsyncThunk("businessImpacts/fetchBusinessImpacts", async () => {
  try {
    const response = await axios.get(BUSINESS_IMPACTS_API_URL);
    const businessImpacts: BusinessImpact[] = response.data.map((businessImpact: any) => ({
      id: `${businessImpact.id}`,
      name: businessImpact.name,
    }));
    return businessImpacts;
  } catch (err) {
    return [];
  }
});

export const { selectAll: selectAllBusinessImpacts } = businessImpactsAdapter.getSelectors((state: RootState) => state.businessImpacts);

export default businessImpactsSlice.reducer;
