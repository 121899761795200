import { useSelector } from "react-redux";
import { Tag } from "primereact/tag";

import { selectAllUrgencies } from "./urgenciesSlice";
import { PropsWithClassName } from "../../../common/types/PropsWithClassName";

interface UrgencyTagProps extends PropsWithClassName {
  value: string;
}

const getSeverity = (id: string) => {
  switch (id) {
    case "2":
      return "success";
    case "1":
      return "warning";
    case "0":
      return "danger";
  }
};

export default function UrgencyTag({ className, value }: UrgencyTagProps) {
  const urgencies = useSelector(selectAllUrgencies);
  const selectedUrgency = urgencies.find((urgency) => urgency.id === value);
  if (!selectedUrgency) {
    return null;
  }

  return <Tag className={`${className} w-4rem font-medium`} value={selectedUrgency.name} severity={getSeverity(selectedUrgency.id)} />;
}
