import { Dropdown } from "primereact/dropdown";

import { TicketType, ticketTypes } from "./TicketType";

interface TicketTypeDropdownProps {
  value?: TicketType;
  onChange: (ticketTypeId: TicketType) => void;
}

export default function TicketTypeDropdown({ value, onChange }: TicketTypeDropdownProps) {
  const selectedTicketType = ticketTypes.find((ticketType) => ticketType.id === value);

  return <Dropdown options={ticketTypes} optionLabel="label" value={selectedTicketType} onChange={(e) => onChange(e.value.id)}></Dropdown>;
}
