import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";

import { LS_AUTH_TOKEN_KEY, LS_REFRESH_TOKEN_KEY, authLocalStorageKeys } from "../../common/utils/common";
import { AuthUser } from "./AuthUser";
import { LoginInfo } from "./LoginInfo";
import { RootState } from "../../app/store";
import { Privilege } from "./Privilege";
import { User } from "../common/users/User";
import { mapApiUser } from "../common/users/usersApi";
import { AUTH_API_URL } from "./authApi";

export interface AuthState {
  authUser?: AuthUser;
  profile?: User;
}

const getUserFromToken = (token?: string): AuthUser | undefined => {
  if (!token) {
    return;
  }

  const decodedToken: any = jwtDecode(token);
  let privilege = Privilege.Anonymous;
  switch (decodedToken.role.toLowerCase()) {
    case "administrator":
      privilege = Privilege.Admin;
      break;
    case "agent":
      privilege = Privilege.Agent;
      break;
    case "user":
      privilege = Privilege.User;
      break;
  }

  return {
    id: decodedToken.nameid,
    firstName: decodedToken.given_name,
    lastName: decodedToken.family_name,
    privilege,
  };
};

const authSlice = createSlice({
  name: "auth",
  initialState: {
    authUser: getUserFromToken(localStorage.getItem(LS_AUTH_TOKEN_KEY) || ""),
    token: localStorage.getItem(LS_AUTH_TOKEN_KEY),
    refreshToken: localStorage.getItem(LS_REFRESH_TOKEN_KEY),
  } as AuthState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(authenticate.fulfilled, (state, action) => {
      state.authUser = action.payload.user;
    });
    builder.addCase(fetchProfile.fulfilled, (state, action) => {
      state.profile = action.payload;
    });
    builder.addCase(updateOutOfOffice.fulfilled, (state, action) => {
      state.profile = action.meta.arg;
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.authUser = undefined;
      state.profile = undefined;
    });
  },
});

export const authenticate = createAsyncThunk("auth/login", async (login: LoginInfo) => {
  const payload = {
    ...login,
  };

  try {
    const response = await axios.post(`${AUTH_API_URL}/login`, payload, {
      headers: { bypassErrorHandling: true },
    });
    const token = response.data.token;
    const refreshToken = response.data.refreshToken;

    localStorage.setItem(LS_AUTH_TOKEN_KEY, token);
    localStorage.setItem(LS_REFRESH_TOKEN_KEY, refreshToken);

    return {
      user: getUserFromToken(token),
    };
  } catch (err: any) {
    if (err.response?.status === 403) {
      toast.error("Nume utilizator sau parola sunt incorecte!");
    }
    throw err;
  }
});

export const fetchProfile = createAsyncThunk("auth/fetchProfile", async (_, { getState }) => {
  const user = selectAuthUser(getState() as RootState);
  const response = await axios.get(`${AUTH_API_URL}/${user!.id}`);
  return mapApiUser(response.data);
});

export const updateOutOfOffice = createAsyncThunk("auth/updateOutOfOffice", async (user: User) => {
  const payload = {
    id: user.id,
    outOfOfficeEnabled: user.outOfOfficeEnabled,
    outOfOfficeStart: user.outOfOfficeStart,
    outOfOfficeEnd: user.outOfOfficeEnd,
    outOfOfficeReplacementId: user.outOfOfficeReplacementId,
  };
  await axios.put(`${AUTH_API_URL}/out-of-office`, payload);
  toast.success("Profilul a fost modificat cu succes!");
});

export const logout = createAsyncThunk("auth/logout", async (_, { getState }) => {
  const token = selectAuthUser(getState() as RootState);
  if (!token) {
    return;
  }
  await axios.post(`${AUTH_API_URL}/revoke`);
  authLocalStorageKeys.forEach((item) => localStorage.removeItem(item));
});

export const selectAuthUser = (state: RootState) => state.auth.authUser!;
export const selectProfile = (state: RootState) => state.auth.profile!;
export const selectIsAuthenticated = (state: RootState) => !!state.auth.authUser;

export default authSlice.reducer;
