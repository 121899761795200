import { useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Card } from "primereact/card";
import { confirmDialog } from "primereact/confirmdialog";
import { Tag } from "primereact/tag";

import { useAppDispatch } from "../../../common/hooks/useStore";
import { createDepartment, deleteDepartment, selectAllDepartments, updateDepartment } from "../../common/departments/departmentsSlice";
import { Department } from "../../common/departments/Department";
import DepartmentForm from "./DepartmentForm";
import NoResultsFound from "../../../common/components/noResultsFound/NoResultsFound";

export default function DepartmentsPage() {
  const dispatch = useAppDispatch();
  const departments = useSelector(selectAllDepartments);
  const [selectedDepartment, setSelectedDepartment] = useState<Department | undefined>(undefined);
  const [showDepartmentDialog, setShowDepartmentDialog] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const closeDialog = () => {
    setSelectedDepartment(undefined);
    setShowDepartmentDialog(false);
  };

  const onCreate = () => {
    setSelectedDepartment(undefined);
    setShowDepartmentDialog(true);
    setIsEditing(false);
  };

  const onEdit = (department: Department) => {
    setSelectedDepartment(department);
    setShowDepartmentDialog(true);
    setIsEditing(true);
  };

  const onDelete = (department: Department) => {
    confirmDialog({
      header: "Confirmare stergere",
      icon: "pi pi-exclamation-triangle",
      message: (
        <div className="text-xl">
          Esti sigur ca vrei sa stergi departamentul <span className="font-bold">{department.name}</span>?
        </div>
      ),
      acceptLabel: "Da",
      rejectLabel: "Nu",
      accept: () => dispatch(deleteDepartment(department)),
    });
  };

  const onSubmit = async (department: Department) => {
    if (isEditing) {
      await dispatch(updateDepartment(department));
    } else {
      await dispatch(createDepartment(department));
    }
    closeDialog();
  };

  return (
    <Card
      title={
        <div className="flex align-items-center justify-content-between">
          <div className="text-2xl font-bold">Departamente</div>
          <Button label="Adauga" icon="pi pi-plus" onClick={() => onCreate()} />
        </div>
      }
    >
      <DataTable size="small" value={departments} emptyMessage={<NoResultsFound />}>
        <Column
          className="w-5rem"
          field="isActive"
          body={(rowData: Department) => {
            return rowData.isActive ? <Tag severity="success">Activ</Tag> : <Tag severity="danger">Inactiv</Tag>;
          }}
        ></Column>
        <Column field="name" header="Nume"></Column>
        <Column
          className="w-17rem"
          body={(rowData: Department) => (
            <div className="flex align-items-center justify-content-end gap-2">
              <Button size="small" label="Editeaza" icon="pi pi-pencil" onClick={() => onEdit(rowData)} />
              <Button size="small" label="Sterge" severity="danger" icon="pi pi-trash" onClick={() => onDelete(rowData)} />
            </div>
          )}
        ></Column>
      </DataTable>
      <Dialog
        className="w-full sm:w-6"
        header={selectedDepartment ? "Editeaza departament" : "Adauga departament"}
        visible={showDepartmentDialog}
        onHide={() => closeDialog()}
      >
        <DepartmentForm department={selectedDepartment} onSubmit={onSubmit} />
      </Dialog>
    </Card>
  );
}
