import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { AutoComplete, AutoCompleteCompleteEvent } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";

import { TicketCost } from "./TicketCost";
import FormGroup from "../../../common/components/form/FormGroup";
import { quickSearchCostCenters, saveTicketCost } from "./ticketCostApi";
import { Ticket } from "../tickets/Ticket";

export interface TicketCostProps {
  ticket: Ticket;
  readonly?: boolean;
}

export default function TicketCostForm({ ticket, readonly }: TicketCostProps) {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<TicketCost>({ defaultValues: ticket.cost });
  const [costCenters, setCostCenters] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const onAutoComplete = async ({ query }: AutoCompleteCompleteEvent) => {
    const costCenters = await quickSearchCostCenters({ departmentId: ticket.category!.departmentId, term: query });
    setCostCenters(costCenters);
  };

  const onSubmit = async (data: TicketCost) => {
    setIsLoading(true);
    await saveTicketCost([ticket.id], data);
    setIsLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex gap-2">
        <FormGroup className="w-8" label="Furnizor" errorMessage={errors.costCenter?.message}>
          <Controller
            name="costCenter"
            control={control}
            rules={{ required: "Furnizorul este obligatoriu" }}
            render={({ field }) => (
              <AutoComplete
                className="w-full"
                inputClassName="w-full"
                placeholder="Furnizor"
                suggestions={costCenters}
                disabled={readonly}
                value={field.value}
                completeMethod={onAutoComplete}
                onChange={(e) => field.onChange(e.value)}
              ></AutoComplete>
            )}
          ></Controller>
        </FormGroup>
        <FormGroup className="w-4" label="Cost" errorMessage={errors.cost?.message}>
          <Controller
            name="cost"
            control={control}
            rules={{ required: "Costul este obligatoriu", min: { value: 0.01, message: "Costul este obligatoriu" } }}
            render={({ field }) => (
              <InputNumber
                className="w-full"
                inputClassName="w-full"
                mode="currency"
                currency="RON"
                locale="ro-RO"
                placeholder="Cost (RON)"
                min={0}
                max={1e6}
                disabled={readonly}
                value={field.value}
                onChange={(e) => field.onChange(e.value)}
              ></InputNumber>
            )}
          ></Controller>
        </FormGroup>
      </div>
      {!readonly && (
        <Button type="submit" className="block ml-auto" label="Salveaza" icon="pi pi-save" loading={isLoading} disabled={isLoading} />
      )}
    </form>
  );
}
