import { selectDayOfWeekById } from "./daysOfWeekSlice";
import { useAppSelector } from "../../../common/hooks/useStore";

interface DayOfWeekLabelProps {
  value: string;
}

export default function DayOfWeekLabel({ value }: DayOfWeekLabelProps) {
  const dayOfWeek = useAppSelector((state) => selectDayOfWeekById(state, value));

  return <div>{dayOfWeek?.name}</div>;
}
