export enum TicketType {
  Incident = 0,
  Request = 1,
}

export const ticketTypes = [
  {
    id: TicketType.Incident,
    label: "Incident",
  },
  {
    id: TicketType.Request,
    label: "Cerere",
  },
];
