export const LS_AUTH_TOKEN_KEY = "SMART_SUPORT_AUTH_TOKEN";
export const LS_REFRESH_TOKEN_KEY = "SMART_SUPORT_REFRESH_TOKEN";
export const LS_AGENT_TICKETS_PAGE_VISIBLE_COLUMNS = "SMART_SUPORT_AGENT_TICKETS_PAGE_VISIBLE_COLUMNS";
export const LS_AGENT_TICKETS_FILTERS = "SMART_SUPORT_AGENT_TICKETS_FILTERS";
export const LS_SELECTED_DEPARTMENT_ID = "SMART_SUPORT_SELECTED_DEPARTMENT_ID";

export const authLocalStorageKeys = [LS_AUTH_TOKEN_KEY, LS_REFRESH_TOKEN_KEY, LS_SELECTED_DEPARTMENT_ID];

export const waitFor = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const fixedWidthColumnStyle = (width: number) => ({
  width: `${width}px`,
  minWidth: `${width}px`,
  maxWidth: `${width}px`,
});

export const checkIfHtmlStringHasText = (htmlString: string) => {
  const text = htmlString.replace(/<[^>]*>/gm, "").replace(/\s+/g, "");
  return text.length > 0;
};
