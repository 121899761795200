import { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { ProgressSpinner } from "primereact/progressspinner";

import { routes } from "./routes";
import { store } from "../../app/store";
import { canAccess } from "../../features/auth/utils";
import { fetchProfile, selectIsAuthenticated, selectAuthUser } from "../../features/auth/authSlice";
import { fetchUrgencies } from "../../features/common/urgencies/urgenciesSlice";
import { fetchBusinessImpacts } from "../../features/common/businessImpacts/businessImpactsSlice";
import { fetchTicketPriorities } from "../../features/common/ticketPriorities/ticketPrioritiesSlice";
import { fetchDaysOfWeek } from "../../features/common/daysOfWeek/daysOfWeekSlice";
import { fetchUserRoles } from "../../features/common/userRoles/userRolesSlice";
import { fetchDepartments } from "../../features/common/departments/departmentsSlice";
import styles from "./ProtectedRoute.module.css";

interface ProtectedRouteProps {
  requiredPrivilege?: number;
}

export default function ProtectedRoute({ requiredPrivilege }: ProtectedRouteProps) {
  const authUser = useSelector(selectAuthUser);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadCommon = async () => {
      await Promise.all([
        store.dispatch(fetchProfile()),
        store.dispatch(fetchUrgencies()),
        store.dispatch(fetchBusinessImpacts()),
        store.dispatch(fetchTicketPriorities()),
        store.dispatch(fetchDaysOfWeek()),
        store.dispatch(fetchDepartments()),
        store.dispatch(fetchUserRoles()),
      ]);
      setIsLoading(false);
    };
    if (isAuthenticated) {
      loadCommon();
    }
  }, []);

  if (!isAuthenticated) {
    return <Navigate to={`${routes.login.path}?redirectUrl=${encodeURIComponent(window.location.pathname)}`} />;
  }
  if (!canAccess(requiredPrivilege, authUser?.privilege)) {
    return <Navigate to={routes.unauthorized.path} />;
  }
  return isLoading ? (
    <div className={`${styles.spinnerContainer} flex align-items-center justify-content-center`}>
      <ProgressSpinner />
    </div>
  ) : (
    <Outlet />
  );
}
