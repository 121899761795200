import { useSelector } from "react-redux";

import ServiceCatalogCard from "../serviceCatalog/ServiceCatalogCard";
import UserTicketsCard from "../tickets/UserTicketsCard";
import { selectActiveDepartments } from "../../common/departments/departmentsSlice";

export default function DashboardPage() {
  const departments = useSelector(selectActiveDepartments);

  return (
    <div className="grid">
      {departments.map((department) => (
        <div key={department.id} className="col-12">
          <ServiceCatalogCard department={department}></ServiceCatalogCard>
        </div>
      ))}
      <div className="col-12">
        <UserTicketsCard></UserTicketsCard>
      </div>
    </div>
  );
}
