import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

import { RootState } from "../../../app/store";
import { Department } from "./Department";
import { FileUploadResult } from "../../../common/types/FileUploadResult";

const DEPARTMENTS_API_URL = `${process.env.REACT_APP_API_BASE_URL}/departments`;

const departmentsAdapter = createEntityAdapter<Department>({});

const initialState = departmentsAdapter.getInitialState();

const departmentsSlice = createSlice({
  name: "departments",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchDepartments.fulfilled, (state, action) => {
      departmentsAdapter.setAll(state, action.payload);
    });
    builder.addCase(createDepartment.fulfilled, (state, action) => {
      departmentsAdapter.addOne(state, action.payload);
    });
    builder.addCase(updateDepartment.fulfilled, (state, action) => {
      const updatedDepartment = action.meta.arg;
      departmentsAdapter.updateOne(state, {
        id: updatedDepartment.id,
        changes: updatedDepartment,
      });
    });
    builder.addCase(deleteDepartment.fulfilled, (state, action) => {
      departmentsAdapter.removeOne(state, action.meta.arg.id);
    });
  },
});

export const fetchDepartments = createAsyncThunk("departments/fetchDepartments", async () => {
  try {
    const response = await axios.get(DEPARTMENTS_API_URL);
    const departments = response.data.map(mapApiDepartment);
    return departments;
  } catch (err) {
    return [];
  }
});

export const createDepartment = createAsyncThunk("departments/createDepartment", async (department: Department) => {
  const response = await axios.post(DEPARTMENTS_API_URL, { ...department });
  const newDepartment = mapApiDepartment(response.data);
  toast.success("Departamentul a fost adaugat cu succes!");
  return newDepartment;
});

export const updateDepartment = createAsyncThunk("departments/updateDepartment", async (department: Department) => {
  const payload = {
    ...department,
    defaultTeamId: parseInt(department.defaultTeamId),
  };
  await axios.put(DEPARTMENTS_API_URL, payload);
  toast.success("Departamentul a fost actualizat cu succes!");
});

export const deleteDepartment = createAsyncThunk("departments/deleteDepartment", async (department: Department) => {
  await axios.delete(`${DEPARTMENTS_API_URL}/${department.id}`);
  toast.success("Departamentul a fost sters cu succes!");
});

export const uploadIcon = createAsyncThunk("departments/uploadIcon", async (file: File) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    const response = await axios.post(`${DEPARTMENTS_API_URL}/file`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    toast.success("Imaginea a fost incarcata cu succes!");
    return response.data as FileUploadResult;
  } catch (err) {
    toast.error(`Eroare la incarcarea fisierului ${file.name}`);
    return null;
  }
});

const mapApiDepartment = (department: any): Department => ({
  id: `${department.id}`,
  defaultTeamId: `${department.defaultTeamId}`,
  name: department.name,
  costCentersEnabled: department.costCentersEnabled,
  internalNotesEnabled: department.internalNotesEnabled,
  agentRequiredOnTicketCreation: department.agentRequiredOnTicketCreation,
  sendNotificationsToSecondaryEmail: department.sendNotificationsToSecondaryEmail,
  fileUri: department.fileUri && `${process.env.REACT_APP_SERVER_BASE_URL}${department.fileUri}`,
  fileName: department.fileName,
  icon: department.icon,
  isActive: department.isActive,
});

export const { selectAll: selectAllDepartments } = departmentsAdapter.getSelectors((state: RootState) => state.departments);
export const selectActiveDepartments = createSelector(selectAllDepartments, (departments) =>
  departments.filter((department) => department.isActive),
);
export const selectDepartmentById = createSelector(
  selectAllDepartments,
  (state: RootState, departmentId?: string) => departmentId,
  (departments, departmentId) => departments.find((department) => department.id === departmentId),
);
export default departmentsSlice.reducer;
