import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Chip } from "primereact/chip";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";

import { Category } from "../../common/categories/Category";
import FormGroup from "../../../common/components/form/FormGroup";
import TicketTypeDropdown from "../../common/ticketType/TicketTypeDropdown";
import IconPicker from "../../../common/components/iconPicker/IconPicker";
import { uploadIcon } from "../../common/categories/categoriesApi";

interface CategoryFormProps {
  allCategories: Category[];
  category?: Category;
  parentCategory?: Category;
  onSubmit: (category: Category) => Promise<void>;
}

export default function CategoryForm(props: CategoryFormProps) {
  const {
    register,
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<Category>({
    defaultValues: {
      ...props.category,
      isActive: props.category?.isActive ?? true,
    },
  });
  const iconFileUploadRef = useRef<FileUpload>(null);
  const [iconFileName, setIconFileName] = useState<string | undefined>(props.category?.fileName);
  const [selectedFirstLevelCategory, setSelectedFirstLevelCategory] = useState<Category | undefined>(undefined);
  const [selectedSecondLevelCategory, setSelectedSecondLevelCategory] = useState<Category | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const allFirstLevelCategories = props.allCategories.filter((category) => category.level === 0);
  const secondLevelCategories = props.allCategories.filter((category) => category.parentId === selectedFirstLevelCategory?.id);

  useEffect(() => {
    if (!props.parentCategory) {
      return;
    }

    if (props.parentCategory.level === 0) {
      setSelectedFirstLevelCategory(props.parentCategory);
    }
    if (props.parentCategory.level === 1) {
      setSelectedSecondLevelCategory(props.parentCategory);
      setSelectedFirstLevelCategory(props.allCategories.find((category) => category.id === props.parentCategory!.parentId));
    }
  }, [props.allCategories, props.parentCategory]);

  const onUpload = async (event: any) => {
    const result = await uploadIcon(event.files[0]);
    const fileName = result?.fileName;
    setIconFileName(fileName ? event.files[0].name : undefined);
    setValue("fileName", fileName ?? "");
    iconFileUploadRef.current?.clear();
  };

  const onSubmit = async (data: Category) => {
    const category = {
      ...data,
      parentId: props.parentCategory?.id ?? data.parentId,
      level: selectedSecondLevelCategory ? 2 : selectedFirstLevelCategory ? 1 : 0,
    };
    category.level = props.category?.level ?? category.level;

    setIsLoading(true);
    await props.onSubmit(category);
    setIsLoading(false);
  };

  const onRemoveFile = () => {
    setIconFileName(undefined);
    setValue("fileName", "");
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup inline label="Activa">
        <Controller
          name="isActive"
          control={control}
          render={({ field }) => <InputSwitch checked={field.value} onChange={(e) => field.onChange(e.value)} />}
        ></Controller>
      </FormGroup>
      <FormGroup label="Nume *" errorMessage={errors.name?.message}>
        <InputText type="text" {...register("name", { required: "Numele este obligatoriu" })} />
      </FormGroup>

      {!props.category && (
        <FormGroup label="Parinte">
          <Controller
            name="parentId"
            control={control}
            render={({ field }) => (
              <div className="flex gap-2">
                <Dropdown
                  className="w-full"
                  optionLabel="name"
                  scrollHeight="300px"
                  options={allFirstLevelCategories}
                  value={selectedFirstLevelCategory}
                  onChange={(e) => {
                    setSelectedFirstLevelCategory(e.value);
                    field.onChange(e.value.id);
                  }}
                ></Dropdown>
                <Dropdown
                  className="w-full"
                  optionLabel="name"
                  scrollHeight="300px"
                  options={secondLevelCategories}
                  value={selectedSecondLevelCategory}
                  onChange={(e) => {
                    setSelectedSecondLevelCategory(e.value);
                    field.onChange(e.value.id);
                  }}
                ></Dropdown>
              </div>
            )}
          ></Controller>
        </FormGroup>
      )}
      {selectedSecondLevelCategory && (
        <FormGroup label="Tip tichet *" errorMessage={errors.ticketType?.message}>
          <Controller
            name="ticketType"
            control={control}
            rules={{ required: "Tip tichet este obligatoriu" }}
            render={({ field }) => <TicketTypeDropdown value={field.value} onChange={(e) => field.onChange(e)} />}
          ></Controller>
        </FormGroup>
      )}
      <FormGroup label="Icoana">
        <Controller
          name="icon"
          control={control}
          render={({ field }) => <IconPicker value={field.value} onChange={(e) => field.onChange(e)}></IconPicker>}
        ></Controller>
      </FormGroup>
      <FormGroup label="Imagine">
        <Controller
          name="fileName"
          control={control}
          render={() => (
            <div className="flex flex-wrap align-items-center gap-2">
              <FileUpload
                auto
                customUpload
                mode="basic"
                accept="image/*"
                chooseLabel="Adauga fisier"
                ref={iconFileUploadRef}
                uploadHandler={onUpload}
                maxFileSize={10 * 1024 * 1024}
                invalidFileSizeMessageDetail="Fisierul depaseste dimensiunea maxima permisa de 1MB"
              />
              {iconFileName && <Chip label={iconFileName} icon="pi pi-file" removable onRemove={() => onRemoveFile()} />}
            </div>
          )}
        ></Controller>
      </FormGroup>
      <Button type="submit" className="block ml-auto" label="Salveaza" icon="pi pi-save" loading={isLoading} disabled={isLoading}></Button>
    </form>
  );
}
