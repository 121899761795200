import { Ticket } from "../../common/tickets/Ticket";

interface MergedTicketsListProps {
  mergedTickets: Ticket[];
}

export default function MergedTicketsList({ mergedTickets }: MergedTicketsListProps) {
  return (
    <div className="flex flex-column gap-2">
      {mergedTickets.map((mergedTicket) => (
        <div key={mergedTicket.id} className="flex gap-4 p-3 border-round bg-white shadow-1">
          <span className="font-bold">{mergedTicket.prettyId}</span>
          <div className="flex flex-column gap-2 flex-1">
            <span className="font-semibold">Titlu</span>
            <span>{mergedTicket.subject}</span>
          </div>
          <div className="flex flex-column gap-2 w-10rem">
            <span className="text-ellipsis">{mergedTicket.location}</span>
            <span>{mergedTicket.createdPretty}</span>
          </div>
          <div className="flex flex-column gap-2 w-10rem">
            <span className="font-semibold">Utilizator</span>
            <span className="text-ellipsis">
              {mergedTicket.owner?.firstName} {mergedTicket.owner?.lastName}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
}
