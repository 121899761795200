import { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";

import NoResultsFound from "../../../common/components/noResultsFound/NoResultsFound";
import { PropsWithClassName } from "../../../common/types/PropsWithClassName";
import { fetchTeamsByDepartment } from "./teamsApi";
import { Team } from "./Team";

interface TeamsDropdownProps extends PropsWithClassName {
  placeholder?: string;
  showClear?: boolean;
  departmentId: string;
  value?: string;
  onChange: (teamId?: string) => void;
}

export default function TeamsDropdown({ className, placeholder, showClear, departmentId, value, onChange }: TeamsDropdownProps) {
  const [allTeams, setAllTeams] = useState<Team[]>([]);
  const selectedTeam = allTeams.find((team) => team.id === value);

  useEffect(() => {
    const doFetchTeamsByDepartment = async () => {
      if (departmentId) {
        const teams = await fetchTeamsByDepartment(departmentId);
        setAllTeams(teams);
      }
    };
    doFetchTeamsByDepartment();
  }, [departmentId]);

  return (
    <Dropdown
      className={`${className}`}
      placeholder={placeholder}
      optionLabel="name"
      options={allTeams}
      value={selectedTeam}
      showClear={showClear}
      emptyMessage={<NoResultsFound />}
      onChange={(e) => onChange(e.value?.id)}
    />
  );
}
