import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PrimeReactProvider } from "primereact/api";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "primereact/resources/themes/lara-light-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import { ConfirmDialog } from "primereact/confirmdialog";
import Quill from "quill";

import "./axios-config";
import Router from "../common/routing/Router";
import { store } from "./store";
import { LayoutContextProvider } from "../common/components/layout/LayoutContext";
import { EnvBar } from "../common/components/envBar/EnvBar";

export default function App() {
  Quill.register(Quill.import("attributors/style/size"), true);
  Quill.register(Quill.import("attributors/style/align"), true);
  Quill.register(Quill.import("attributors/style/direction"), true);

  return (
    <BrowserRouter>
      <Provider store={store}>
        <PrimeReactProvider>
          <LayoutContextProvider>
            <Router></Router>
            <EnvBar />
            <ToastContainer hideProgressBar pauseOnHover theme="colored"></ToastContainer>
            <ConfirmDialog />
          </LayoutContextProvider>
        </PrimeReactProvider>
      </Provider>
    </BrowserRouter>
  );
}
