import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import { RootState } from "../../../app/store";
import { UserRole } from "./UserRole";

const USER_ROLES_API_URL = `${process.env.REACT_APP_API_BASE_URL}/common/UserRoles`;

const userRolesAdapter = createEntityAdapter<UserRole>({});

const initialState = userRolesAdapter.getInitialState();

const userRolesSlice = createSlice({
  name: "userRoles",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUserRoles.fulfilled, (state, action) => {
      userRolesAdapter.setAll(state, action.payload);
    });
  },
});

export const fetchUserRoles = createAsyncThunk("userRoles/fetchUserRoles", async () => {
  try {
    const response = await axios.get(USER_ROLES_API_URL);
    const userRoles: UserRole[] = response.data.map((role: any) => ({
      id: `${role.id}`,
      name: role.name,
    }));
    return userRoles;
  } catch (err) {
    return [];
  }
});

export const { selectAll: selectAllUserRoles, selectById: selectUserRoleById } = userRolesAdapter.getSelectors(
  (state: RootState) => state.userRoles,
);

export default userRolesSlice.reducer;
