import { useEffect, useState } from "react";
import { Card } from "primereact/card";

import SmtpSettingsForm from "./SmtpSettingsForm";
import { fetchSystemSettings, updateSystemSettings } from "../../common/systemSettings/systemSettingsApi";
import { SystemSettings } from "../../common/systemSettings/SystemSettings";

export default function SystemSettingsPage() {
  const [systemSettings, setSystemSettings] = useState<SystemSettings>();

  useEffect(() => {
    doFetchSystemSettings();
  }, []);

  const doFetchSystemSettings = async () => {
    const systemSettings = await fetchSystemSettings();
    setSystemSettings(systemSettings);
  };

  const onSubmit = async (systemSettings: SystemSettings) => {
    await updateSystemSettings(systemSettings);
  };

  return (
    <div className="grid">
      <Card className="col-12" title="Setari SMTP">
        {systemSettings && <SmtpSettingsForm systemSettings={systemSettings} onSubmit={onSubmit} />}
      </Card>
    </div>
  );
}
