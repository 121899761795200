import { toast } from "react-toastify";
import { AxiosError, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import axios from "axios";

import { routes } from "../common/routing/routes";
import { LS_AUTH_TOKEN_KEY } from "../common/utils/common";
import { refreshToken } from "../features/auth/authApi";

const onRequest = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  const token = localStorage.getItem(LS_AUTH_TOKEN_KEY);
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response;
};

const onResponseError = async (error: AxiosError): Promise<any> => {
  console.error(error);

  if (error.config?.headers?.bypassErrorHandling) {
    delete error.config.headers.bypassErrorHandling;
    return Promise.reject(error);
  }

  const status = error.response?.status;
  switch (status) {
    case 401:
      try {
        const originalResponse = await doRefreshToken(error.config!);
        return Promise.resolve(originalResponse);
      } catch (err) {
        toast.error("Sesiunea a expirat, te rog sa te autentifici din nou!");
        window.location.href = routes.logout.path;
      }
      break;
    case 403:
      toast.error("Nu ai suficiente drepturi pentru a accesa aceasta sectiune!");
      break;
    default:
      const response = error?.response?.data as any;
      const message = response?.detail || error?.message;
      toast.error(`A intervenit o eroare, te rog incearca mai tarziu! [${message}]`);
      break;
  }

  return Promise.reject(error);
};

const doRefreshToken = async (originalRequest: AxiosRequestConfig) => {
  await refreshToken();
  return axios.request(originalRequest);
};

axios.interceptors.request.use(onRequest, onRequestError);
axios.interceptors.response.use(onResponse, onResponseError);
