import { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";

import NoResultsFound from "../../../common/components/noResultsFound/NoResultsFound";
import { Ticket } from "./Ticket";
import { fetchTicket, quickSearchTickets } from "./ticketsApi";

interface TicketsDropdownProps {
  value?: string;
  onChange: (ticketId: string) => void;
}

export default function TicketsDropdown({ value, onChange }: TicketsDropdownProps) {
  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [selectedTicket, setSelectedTicket] = useState<Ticket>();
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchSelectedTicket = async () => {
      if (value) {
        const ticket = await fetchTicket(value);
        setSelectedTicket(ticket);
      } else {
        setSelectedTicket(undefined);
      }
    };
    fetchSelectedTicket();
  }, [value]);

  useEffect(() => {
    const doQuickSearchTickets = async () => {
      if (searchTerm) {
        const tickets = await quickSearchTickets(searchTerm);
        setTickets(tickets);
      }
    };
    const timeoutId = setTimeout(() => doQuickSearchTickets(), 500);
    return () => clearTimeout(timeoutId);
  }, [searchTerm]);

  useEffect(() => {
    const quickSearchInitialTickets = async () => {};
    quickSearchInitialTickets();
  }, []);

  return (
    <Dropdown
      optionLabel="prettyId"
      value={selectedTicket}
      options={tickets}
      filter
      filterTemplate={
        <InputText className="w-full" placeholder="Cauta tichete" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
      }
      itemTemplate={(ticket: Ticket) => (
        <div className="flex">
          <span className="text-ellipsis">
            {ticket.prettyId} - {ticket.subject}
          </span>
        </div>
      )}
      emptyMessage={<NoResultsFound />}
      onChange={(e) => onChange?.(e.value?.id)}
    ></Dropdown>
  );
}
