import { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import { User } from "../../common/users/User";
import NoResultsFound from "../../../common/components/noResultsFound/NoResultsFound";
import { PropsWithClassName } from "../../../common/types/PropsWithClassName";
import { searchUsers } from "../../common/users/usersApi";

interface TeamUsersProps extends PropsWithClassName {
  teamId: string;
}

export default function TeamUsers({ className, teamId }: TeamUsersProps) {
  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    const doSearchUsers = async () => {
      const response = await searchUsers({ teamId, pageNumber: 0, pageSize: 1000 });
      setUsers(response.users);
    };
    doSearchUsers();
  }, [teamId]);

  return (
    <DataTable className={`${className}`} size="small" value={users} emptyMessage={<NoResultsFound />}>
      <Column field="emailAddress" header="Email"></Column>
      <Column field="lastName" header="Nume"></Column>
      <Column field="firstName" header="Prenume"></Column>
    </DataTable>
  );
}
