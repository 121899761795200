import { configureStore } from "@reduxjs/toolkit";

import authReducer from "../features/auth/authSlice";
import urgenciesReducer from "../features/common/urgencies/urgenciesSlice";
import businessImpactsReducer from "../features/common/businessImpacts/businessImpactsSlice";
import ticketPrioritiesReducer from "../features/common/ticketPriorities/ticketPrioritiesSlice";
import daysOfWeekReducer from "../features/common/daysOfWeek/daysOfWeekSlice";
import departmentsReducer from "../features/common/departments/departmentsSlice";
import userRolesReducer from "../features/common/userRoles/userRolesSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    urgencies: urgenciesReducer,
    businessImpacts: businessImpactsReducer,
    ticketPriorities: ticketPrioritiesReducer,
    daysOfWeek: daysOfWeekReducer,
    departments: departmentsReducer,
    userRoles: userRolesReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
