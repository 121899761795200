import { useEffect, useState } from "react";
import { Card } from "primereact/card";

import ServiceCatalogCategoryGrid from "./ServiceCatalogCategoryGrid";
import ServiceCatalogBreadCrumb from "./ServiceCatalogBreadCrumb";
import { Category } from "../../common/categories/Category";
import { PropsWithClassName } from "../../../common/types/PropsWithClassName";
import NoResultsFound from "../../../common/components/noResultsFound/NoResultsFound";
import { Department } from "../../common/departments/Department";
import ServiceCatalogCategory from "./ServiceCatalogCategory";
import { fetchCategoriesByDepartment } from "../../common/categories/categoriesApi";

interface ServiceCatalogCardProps extends PropsWithClassName {
  department: Department;
}

export default function ServiceCatalogCard({ className, department }: ServiceCatalogCardProps) {
  const [allCategories, setAllCategories] = useState<Category[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<Category | undefined>(undefined);
  const childCategories = selectedCategory
    ? allCategories.filter((c) => c.parentId === selectedCategory.id)
    : allCategories.filter((c) => c.level === 0);

  useEffect(() => {
    const fetchCategories = async () => {
      if (department) {
        const allCategories = await fetchCategoriesByDepartment(department.id);
        const activeCategories = allCategories.filter((category) => category.isActive);
        setAllCategories(activeCategories);
      }
    };
    fetchCategories();
  }, [department]);

  const renderCategories = () => {
    return childCategories.length ? (
      <ServiceCatalogCategoryGrid
        department={department}
        allCategories={allCategories}
        childCategories={childCategories}
        category={selectedCategory}
        onCategoryClick={(category) => setSelectedCategory(category)}
      ></ServiceCatalogCategoryGrid>
    ) : selectedCategory ? (
      <ServiceCatalogCategory
        department={department}
        allCategories={allCategories}
        category={selectedCategory}
        onCategoryClick={(category) => setSelectedCategory(category)}
      ></ServiceCatalogCategory>
    ) : (
      <NoResultsFound />
    );
  };

  return (
    <Card className={`${className}`}>
      <div className="flex flex-column gap-2">
        <ServiceCatalogBreadCrumb
          allCategories={allCategories}
          department={department}
          category={selectedCategory}
          onCategoryClick={(category) => setSelectedCategory(category)}
        ></ServiceCatalogBreadCrumb>
        {renderCategories()}
      </div>
    </Card>
  );
}
