import axios from "axios";
import { Note, SearchNotesFilter } from "./Note";
import { format } from "date-fns";
import { toast } from "react-toastify";

import { FileUploadResult } from "../../../common/types/FileUploadResult";
import { emptyPaginationDetails, mapApiPaginationDetails, mapToApiPagination } from "../../../common/api/paginationHelper";
import { mapApiUser } from "../users/usersApi";
import { convertISOStringToDate } from "../../../common/utils/dateHelper";

const NOTES_API_URL = `${process.env.REACT_APP_API_BASE_URL}/notes`;

export const searchExternalNotes = async (filter: SearchNotesFilter) => {
  return doSearch(filter, `${NOTES_API_URL}/search`);
};

export const searchAllNotes = async (filter: SearchNotesFilter) => {
  return doSearch(filter, `${NOTES_API_URL}/search-all`);
};

export const createNote = async (note: Partial<Note>) => {
  const payload = {
    type: note.type,
    ticketId: parseInt(note.ticketId!),
    userId: note.userId,
    noteText: note.noteText || "",
    noteAttachments: note.noteAttachments || [],
  };
  const response = await axios.post(`${NOTES_API_URL}`, payload);
  toast.success("Nota a fost creata cu succes!");
  const newNote = mapApiNote(response.data);
  return newNote;
};

export const uploadFile = async (file: File) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    const response = await axios.post(`${NOTES_API_URL}/file`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data as FileUploadResult;
  } catch (err) {
    toast.error(`Eroare la incarcarea fisierului ${file.name}`);
    return null;
  }
};

export const downloadFile = async (fileUri: string, originalFileName: string) => {
  try {
    const response = await axios.get(fileUri, {
      responseType: "blob",
    });

    const href = URL.createObjectURL(response.data);
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", originalFileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  } catch (err) {
    toast.error(`Eroare la descarcarea fisierului`);
  }
};

export const fetchNoteFile = async (attachmentId: string) => {
  const response = await axios.get(`${NOTES_API_URL}/file/${attachmentId}`, {
    responseType: "arraybuffer",
  });
  const imageData = btoa(
    new Uint8Array(response.data).reduce((data, byte) => {
      return data + String.fromCharCode(byte);
    }, ""),
  );
  return imageData;
};

export const mapApiNote = (apiNote: any): Note => {
  const created = convertISOStringToDate(`${apiNote.created}Z`)!;

  return {
    id: `${apiNote.id}`,
    type: apiNote.type,
    ticketId: `${apiNote.ticketId}`,
    userId: `${apiNote.userId}`,
    user: apiNote.user && mapApiUser(apiNote.user),
    noteText: apiNote.noteText || "",
    noteAttachments: (apiNote.noteAttachments || []).map((attachment: any) => ({
      id: `${attachment.id}`,
      fileName: attachment.fileName,
      fileUri: attachment.fileUri && `${process.env.REACT_APP_SERVER_BASE_URL}${attachment.fileUri}`,
      originalFileName: attachment.originalFileName,
    })),
    createdTimestamp: created.getTime(),
    createdPretty: format(created, "dd/MM/yyyy HH:mm"),
  };
};

const doSearch = async (filter: SearchNotesFilter, url: string) => {
  try {
    const payload = {
      ticketId: filter.ticketId,
      noteTypes: filter.noteTypes || [],
      searchTerm: filter.searchTerm,
      ...mapToApiPagination(filter),
    };
    const response = await axios.post(url, payload);
    const pagination = mapApiPaginationDetails(response.data);
    const notes: Note[] = response.data.items.map(mapApiNote);
    return {
      pagination,
      notes,
    };
  } catch (err) {
    return {
      pagination: emptyPaginationDetails,
      notes: [],
    };
  }
};
