import { PropsWithClassName } from "../../../common/types/PropsWithClassName";
import { TicketType } from "../ticketType/TicketType";

interface TicketIconProps extends PropsWithClassName {
  ticketType: TicketType;
}

export default function TicketIcon({ className, ticketType }: TicketIconProps) {
  if (ticketType === TicketType.Incident) {
    return <i className={`${className} pi pi-exclamation-triangle text-yellow-500`} title="Incident"></i>;
  }
  if (ticketType === TicketType.Request) {
    return <i className={`${className} pi pi-plus text-cyan-500`} title="Cerere"></i>;
  }
  return <div>Unknown TicketType</div>;
}
