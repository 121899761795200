import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import { RootState } from "../../../app/store";
import { Urgency } from "./Urgency";

const URGENCIES_API_URL = `${process.env.REACT_APP_API_BASE_URL}/common/Urgencies`;

const urgenciesAdapter = createEntityAdapter<Urgency>({});

const initialState = urgenciesAdapter.getInitialState();

const urgenciesSlice = createSlice({
  name: "urgencies",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUrgencies.fulfilled, (state, action) => {
      urgenciesAdapter.setAll(state, action.payload);
    });
  },
});

export const fetchUrgencies = createAsyncThunk("urgencies/fetchUrgencies", async () => {
  try {
    const response = await axios.get(URGENCIES_API_URL);
    const urgencies: Urgency[] = response.data.map((urgency: any) => ({
      id: `${urgency.id}`,
      name: urgency.name,
    }));
    return urgencies;
  } catch (err) {
    return [];
  }
});

export const { selectAll: selectAllUrgencies } = urgenciesAdapter.getSelectors((state: RootState) => state.urgencies);

export default urgenciesSlice.reducer;
