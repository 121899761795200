import { useState } from "react";
import { Card } from "primereact/card";

import NonWorkingDaysTable from "../nonWorkingDays/NonWorkingDaysTable";
import WorkingHoursTable from "./WorkingHoursTable";
import DepartmentsDropdownCard from "../../common/departments/DepartmentsDropdownCard";
import { LS_SELECTED_DEPARTMENT_ID } from "../../../common/utils/common";

export default function WorkingHoursPage() {
  const [selectedDepartmentId, setSelectedDepartmentId] = useState(localStorage.getItem(LS_SELECTED_DEPARTMENT_ID) || "");

  return (
    <div className="flex flex-column gap-2">
      <DepartmentsDropdownCard
        showAll
        value={selectedDepartmentId}
        onChange={(department) => setSelectedDepartmentId(department)}
      ></DepartmentsDropdownCard>
      <div className="grid">
        <div className="col-12 lg:col-7">
          <Card title="Zile lucratoare">
            <WorkingHoursTable departmentId={selectedDepartmentId} />
          </Card>
        </div>
        <div className="col-12 lg:col-5">
          <Card title="Zile libere">
            <NonWorkingDaysTable departmentId={selectedDepartmentId} />
          </Card>
        </div>
      </div>
    </div>
  );
}
