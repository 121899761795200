import { useState } from "react";

import DepartmentsDropdownCard from "../../common/departments/DepartmentsDropdownCard";
import TicketStatusesTable from "./TicketStatusesTable";
import { LS_SELECTED_DEPARTMENT_ID } from "../../../common/utils/common";

export default function TicketStatusesPage() {
  const [selectedDepartmentId, setSelectedDepartmentId] = useState(localStorage.getItem(LS_SELECTED_DEPARTMENT_ID) || "");

  return (
    <div className="flex flex-column gap-2">
      <DepartmentsDropdownCard
        showAll
        value={selectedDepartmentId}
        onChange={(department) => setSelectedDepartmentId(department)}
      ></DepartmentsDropdownCard>
      <TicketStatusesTable departmentId={selectedDepartmentId} />
    </div>
  );
}
