import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

import UrgencyTag from "../../common/urgencies/UrgencyTag";
import { Ticket } from "../../common/tickets/Ticket";
import { routes } from "../../../common/routing/routes";
import NoResultsFound from "../../../common/components/noResultsFound/NoResultsFound";
import { selectAuthUser } from "../../auth/authSlice";
import {
  defaultPageSize,
  defaultPaginationOptions,
  emptyPaginationDetails,
  getPaginationFirst,
  getPaginationOptionsFromDataTable,
} from "../../../common/api/paginationHelper";
import TicketStatusButtons from "../../common/ticketStatuses/TicketStatusButtons";
import { searchOwnTickets } from "../../common/tickets/ticketsApi";
import { inWorkStatusesIds } from "../../common/ticketStatuses/TicketStatus";

export default function UserTicketsCard() {
  const navigate = useNavigate();
  const authUser = useSelector(selectAuthUser);
  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [pagination, setPagination] = useState(emptyPaginationDetails);
  const [first, setFirst] = useState(getPaginationFirst(pagination.pageNumber, defaultPageSize));
  const [rows, setRows] = useState(defaultPageSize);
  const [selectedTicketStatuses, setSelectedTicketStatuses] = useState<string[] | undefined>(inWorkStatusesIds);

  useEffect(() => {
    const doFetchTickets = async () => {
      const response = await searchOwnTickets({
        ownerId: authUser!.id,
        statuses: selectedTicketStatuses,
        sortParameters: [
          {
            columnName: "Created",
            isDescending: true,
          },
        ],
        ...getPaginationOptionsFromDataTable(first, rows),
      });
      setPagination(response.pagination);
      setTickets(response.tickets);
    };
    doFetchTickets();
  }, [first, rows, selectedTicketStatuses]);

  return (
    <Card
      title={
        <div className="flex align-items-center justify-content-between">
          <div className="text-2xl font-bold">Tichete</div>
          <TicketStatusButtons
            inline
            value={selectedTicketStatuses}
            onChange={(ticketStatuses) => setSelectedTicketStatuses(ticketStatuses)}
          ></TicketStatusButtons>
        </div>
      }
    >
      <DataTable
        size="small"
        lazy
        paginator
        first={first}
        rows={rows}
        rowsPerPageOptions={defaultPaginationOptions}
        totalRecords={pagination.totalCount}
        value={tickets}
        emptyMessage={<NoResultsFound />}
        rowClassName={() => "cursor-pointer"}
        onRowClick={(e) => navigate(`${routes.userTicketDetails.navigatePath}/${e.data.id}`)}
        onPage={(e) => {
          setFirst(e.first);
          setRows(e.rows);
        }}
      >
        <Column className="w-7rem" header="Id" field="prettyId"></Column>
        <Column header="Titlu" field="subject"></Column>
        <Column className="w-12rem" header="Status" field="statusType.name"></Column>
        <Column
          className="w-9rem"
          header="Prioritate"
          body={(rowData: Ticket) => <UrgencyTag value={rowData.urgency}></UrgencyTag>}
        ></Column>
        <Column className="w-10rem" header="Data sesizarii" field="createdPretty"></Column>
      </DataTable>
    </Card>
  );
}
