import { useEffect, useState } from "react";

import { Question } from "../../common/questions/Question";
import { searchPublishedQuestions } from "../../common/questions/questionsApi";
import QuestionPanel from "../../common/questions/QuestionPanel";

interface CreateTicketSuggestionsProps {
  departmentId: string;
  searchTerm?: string;
}

export default function CreateTicketSuggestions({ departmentId, searchTerm }: CreateTicketSuggestionsProps) {
  const [questions, setQuestions] = useState<Question[]>([]);

  useEffect(() => {
    const timeoutId = setTimeout(async () => {
      if ((searchTerm?.length as number) > 3) {
        const result = await searchPublishedQuestions({
          departmentId,
          searchTerm,
          pageNumber: 0,
          pageSize: 5,
        });
        setQuestions(result.questions);
      } else {
        setQuestions([]);
      }
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [searchTerm]);

  return (
    <>
      {questions.length > 0 && (
        <div className="flex flex-column gap-2 p-4 shadow-2 fadein">
          <div className="font-semibold">Sugestii de rezolvare</div>
          <div>
            {questions.map((question) => {
              return <QuestionPanel key={question.id} question={question} />;
            })}
          </div>
        </div>
      )}
    </>
  );
}
