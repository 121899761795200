import { useNavigate } from "react-router";

import TicketLabel from "../../common/tickets/TicketLabel";
import { Category } from "../../common/categories/Category";
import { routes } from "../../../common/routing/routes";
import { Department } from "../../common/departments/Department";
import { TicketType } from "../../common/ticketType/TicketType";

interface ServiceCatalogCategoryActionsProps {
  department: Department;
  category: Category;
}

export default function ServiceCatalogCategoryActions({ department, category }: ServiceCatalogCategoryActionsProps) {
  const navigate = useNavigate();

  return (
    <div className="flex flex-wrap gap-2">
      {category?.ticketType === TicketType.Incident && (
        <TicketLabel
          className="cursor-pointer"
          ticketType={TicketType.Incident}
          creating
          iconClassName="text-xs"
          textClassName="text-xs font-medium hover:underline"
          colored
          onClick={() => navigate(`${routes.createIncident.path}?departmentId=${department.id}&categoryId=${category.id}`)}
        ></TicketLabel>
      )}
      {category?.ticketType === TicketType.Request && (
        <TicketLabel
          className="cursor-pointer"
          ticketType={TicketType.Request}
          creating
          iconClassName="text-xs"
          textClassName="text-xs font-medium hover:underline"
          colored
          onClick={() => navigate(`${routes.createRequest.path}?departmentId=${department.id}&categoryId=${category.id}`)}
        ></TicketLabel>
      )}
    </div>
  );
}
