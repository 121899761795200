import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import { RootState } from "../../../app/store";
import { TicketPriority } from "./TicketPriority";

const TICKET_PRIORITIES_API_URL = `${process.env.REACT_APP_API_BASE_URL}/common/TicketPriorities`;

const ticketPrioritiesAdapter = createEntityAdapter<TicketPriority>({});

const initialState = ticketPrioritiesAdapter.getInitialState();

const ticketPrioritiesSlice = createSlice({
  name: "ticketPriorities",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTicketPriorities.fulfilled, (state, action) => {
      ticketPrioritiesAdapter.setAll(state, action.payload);
    });
  },
});

export const fetchTicketPriorities = createAsyncThunk("ticketPriorities/fetchTicketPriorities", async () => {
  try {
    const response = await axios.get(TICKET_PRIORITIES_API_URL);
    const ticketPriorities: TicketPriority[] = response.data.map((ticketPriority: any) => ({
      id: `${ticketPriority.id}`,
      name: ticketPriority.name,
    }));
    return ticketPriorities;
  } catch (err) {
    return [];
  }
});

export const { selectAll: selectAllTicketPriorities } = ticketPrioritiesAdapter.getSelectors((state: RootState) => state.ticketPriorities);

export default ticketPrioritiesSlice.reducer;
