import { format, parse, parseISO } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

export function convertUTCTimeStringToTimeString(time?: string) {
  if (!time) {
    return undefined;
  }

  const parts = time.split(":");
  const localDate = new Date();
  localDate.setUTCHours(parseInt(parts[0]));
  localDate.setUTCMinutes(parseInt(parts[1]));
  localDate.setUTCSeconds(parseInt(parts[2]));
  const localTimeString = format(localDate, "HH:mm:ss");
  return localTimeString;
}

export function convertTimeStringToUTCTimeString(time?: string) {
  if (!time) {
    return undefined;
  }

  const localDate = parse(time, "HH:mm:ss", new Date());
  const utcDate = utcToZonedTime(localDate, "UTC");
  const utcTimeString = format(utcDate, "HH:mm:ss");
  return utcTimeString;
}

export function convertTimeStringToDate(time?: string) {
  if (!time) {
    return undefined;
  }
  return parse(time, "HH:mm:ss", new Date());
}

export function convertDateToTimeString(date?: Date) {
  if (!date) {
    return undefined;
  }
  return format(date, "HH:mm:ss");
}

export function convertISOStringToDate(dateString?: string) {
  if (!dateString) {
    return undefined;
  }
  return parseISO(dateString);
}

export function convertDateToISOString(date?: Date) {
  if (!date) {
    return undefined;
  }
  return date.toISOString();
}

export function convertDateStringToDate(dateString?: string) {
  if (!dateString) {
    return undefined;
  }
  return parse(dateString, "yyyy-MM-dd", new Date());
}

export function convertDateToDateString(date?: Date) {
  if (!date) {
    return undefined;
  }
  return format(date, "yyyy-MM-dd");
}
