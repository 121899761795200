import { SelectButton } from "primereact/selectbutton";

import { PropsWithClassName } from "../../../common/types/PropsWithClassName";
import { closedStatusesIds, inWorkStatusesIds } from "./TicketStatus";

interface TicketStatusButtonsProps extends PropsWithClassName {
  inline?: boolean;
  value?: string[];
  onChange: (ticketStatusIds: string[] | undefined) => void;
}

export default function TicketStatusButtons({ className, inline, value, onChange }: TicketStatusButtonsProps) {
  const options = [
    {
      name: "In lucru",
      value: inWorkStatusesIds.sort(),
    },
    {
      name: "Inchise",
      value: closedStatusesIds.sort(),
    },
  ];

  return (
    <SelectButton
      className={`${className} ${inline ? "" : "w-full"} select-button-small`}
      optionLabel="name"
      options={options}
      value={value?.sort()}
      onChange={(e) => onChange(e.target.value)}
    />
  );
}
