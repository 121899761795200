import { PropsWithClassName } from "../../../common/types/PropsWithClassName";
import { TicketType } from "../ticketType/TicketType";
import TicketIcon from "./TicketIcon";

interface CreateTicketLabelProps extends PropsWithClassName {
  ticketType: TicketType;
  creating?: boolean;
  colored?: boolean;
  iconClassName?: string;
  textClassName?: string;
  onClick?: () => void;
}

const containerClassName = "flex align-items-center gap-2";

export default function TicketLabel({
  ticketType,
  creating,
  colored,
  className,
  iconClassName,
  textClassName,
  onClick,
}: CreateTicketLabelProps) {
  if (ticketType === TicketType.Incident) {
    return (
      <div className={`${className} ${containerClassName} ${colored ? "text-yellow-500" : ""}`} onClick={onClick}>
        <TicketIcon className={iconClassName} ticketType={ticketType}></TicketIcon>
        <span className={`${textClassName}`}>{creating ? "Inregistreaza" : ""} incident</span>
      </div>
    );
  }
  if (ticketType === TicketType.Request) {
    return (
      <div className={`${className} ${containerClassName} ${colored ? "text-cyan-500" : ""}`} onClick={onClick}>
        <TicketIcon className={iconClassName} ticketType={ticketType}></TicketIcon>
        <span className={`${textClassName}`}>{creating ? "Creeaza " : ""} cerere</span>
      </div>
    );
  }
  return <div>Tip tichet necunoscut</div>;
}
