import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Card } from "primereact/card";
import { confirmDialog } from "primereact/confirmdialog";
import { FilterMatchMode } from "primereact/api";
import { InputSwitch } from "primereact/inputswitch";

import { Question } from "../../common/questions/Question";
import QuestionForm from "./QuestionForm";
import DepartmentsDropdownCard from "../../common/departments/DepartmentsDropdownCard";
import NoResultsFound from "../../../common/components/noResultsFound/NoResultsFound";
import {
  defaultPageSize,
  defaultPaginationOptions,
  emptyPaginationDetails,
  getPaginationFirst,
  getPaginationOptionsFromDataTable,
} from "../../../common/api/paginationHelper";
import { PaginationDetails } from "../../../common/types/Pagination";
import { createQuestion, deleteQuestion, searchAllQuestions, updateQuestion } from "../../common/questions/questionsApi";
import { Privilege } from "../../auth/Privilege";
import { selectAuthUser, selectProfile } from "../../auth/authSlice";
import ProtectedChildren from "../../../common/routing/ProtectedChildren";
import { canAccess } from "../../auth/utils";
import { LS_SELECTED_DEPARTMENT_ID } from "../../../common/utils/common";

export default function QuestionsPage() {
  const authUser = useSelector(selectAuthUser);
  const profile = useSelector(selectProfile);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [pagination, setPagination] = useState<PaginationDetails>(emptyPaginationDetails);
  const [first, setFirst] = useState(getPaginationFirst(pagination.pageNumber, defaultPageSize));
  const [rows, setRows] = useState(defaultPageSize);
  const [filters, setFilters] = useState<any>({
    questionText: { value: null, matchMode: FilterMatchMode.EQUALS },
  });
  const [selectedDepartmentId, setSelectedDepartmentId] = useState(localStorage.getItem(LS_SELECTED_DEPARTMENT_ID) || profile.departmentId);
  const [selectedQuestion, setSelectedQuestion] = useState<Question | undefined>(undefined);
  const [showQuestionDialog, setShowQuestionDialog] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const isAdmin = canAccess(Privilege.Admin, authUser.privilege);

  useEffect(() => {
    doSearchQuestions();
  }, [first, rows, filters, selectedDepartmentId]);

  const doSearchQuestions = async () => {
    if (selectedDepartmentId) {
      const response = await searchAllQuestions({
        departmentId: selectedDepartmentId,
        searchTerm: filters.questionText.value,
        ...getPaginationOptionsFromDataTable(first, rows),
      });
      setPagination(response.pagination);
      setQuestions(response.questions);
    }
  };

  const closeDialog = () => {
    setSelectedQuestion(undefined);
    setShowQuestionDialog(false);
  };

  const onCreate = () => {
    setSelectedQuestion(undefined);
    setShowQuestionDialog(true);
    setIsEditing(false);
  };

  const onEdit = (question: Question) => {
    setSelectedQuestion(question);
    setShowQuestionDialog(true);
    setIsEditing(true);
  };

  const onSubmit = async (question: Question) => {
    question.departmentId = selectedDepartmentId;
    if (isEditing) {
      await updateQuestion(question);
    } else {
      await createQuestion(question);
    }
    await doSearchQuestions();
    closeDialog();
  };

  const onDelete = (question: Question) => {
    confirmDialog({
      header: "Confirmare stergere",
      icon: "pi pi-exclamation-triangle",
      message: (
        <div className="text-xl">
          Esti sigur ca vrei sa stergi intrebarea <span className="font-bold">{question.questionText}</span>?
        </div>
      ),
      acceptLabel: "Da",
      rejectLabel: "Nu",
      accept: async () => {
        await deleteQuestion(question);
        await doSearchQuestions();
      },
    });
  };

  return (
    <div className="flex flex-column gap-2">
      <ProtectedChildren requiredPrivilege={Privilege.Admin}>
        <DepartmentsDropdownCard
          className="mb-4"
          value={selectedDepartmentId}
          onChange={(department) => setSelectedDepartmentId(department)}
        ></DepartmentsDropdownCard>
      </ProtectedChildren>
      <Card
        title={
          <div className="flex align-items-center justify-content-between">
            <div className="text-2xl font-bold">Intrebari frecvente</div>
            <Button label="Adauga" icon="pi pi-plus" onClick={() => onCreate()} />
          </div>
        }
      >
        <DataTable
          size="small"
          lazy
          value={questions}
          paginator
          first={first}
          rows={rows}
          rowsPerPageOptions={defaultPaginationOptions}
          totalRecords={pagination.totalCount}
          filterDisplay="row"
          filters={filters}
          filterDelay={500}
          onPage={(e) => {
            setFirst(e.first);
            setRows(e.rows);
          }}
          onFilter={(e) => setFilters(e.filters)}
          emptyMessage={<NoResultsFound />}
        >
          <Column header="Intrebare" field="questionText" filter showFilterMenu={false}></Column>
          <Column
            className="w-6rem"
            header="Activa"
            field="isPublished"
            body={(rowData) => <InputSwitch checked={rowData.isPublished} disabled />}
          ></Column>
          <Column className="w-6rem" header="Vizualizari" field="views"></Column>
          <Column className="w-6rem" header="Aprecieri" field="absoluteLikes"></Column>
          <Column className="w-8rem" header="Autor" field="createdBy"></Column>
          <Column
            className="w-17rem"
            body={(rowData: Question) => (
              <div className="flex align-items-center justify-content-end gap-2">
                <Button size="small" label="Editeaza" icon="pi pi-pencil" onClick={() => onEdit(rowData)} />
                {isAdmin && <Button size="small" label="Sterge" severity="danger" icon="pi pi-trash" onClick={() => onDelete(rowData)} />}
              </div>
            )}
          ></Column>
        </DataTable>
        <Dialog
          className="w-full sm:w-6"
          header={selectedQuestion ? "Editeaza intrebare" : "Adauga intrebare"}
          visible={showQuestionDialog}
          onHide={() => closeDialog()}
        >
          <QuestionForm question={selectedQuestion} onSubmit={onSubmit} />
        </Dialog>
      </Card>
    </div>
  );
}
