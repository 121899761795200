import { ReactElement } from "react";
import { Column, ColumnFilterElementTemplateOptions } from "primereact/column";
import { FilterMatchMode } from "primereact/api";

import { LS_AGENT_TICKETS_FILTERS, LS_AGENT_TICKETS_PAGE_VISIBLE_COLUMNS, fixedWidthColumnStyle } from "../../../common/utils/common";
import { Ticket } from "../../common/tickets/Ticket";
import { UserRoleEnum } from "../../common/userRoles/UserRole";
import { inWorkStatusesIds } from "../../common/ticketStatuses/TicketStatus";
import UrgencyTag from "../../common/urgencies/UrgencyTag";
import SlaProgressBar from "../../common/tickets/SlaProgressBar";
import UrgencyDropdown from "../../common/urgencies/UrgenciesDropdown";
import UsersDropdown from "../../common/users/UsersDropdown";
import TicketStatusMultiSelect from "../../common/ticketStatuses/TicketStatusMultiSelect";
import TeamsDropdown from "../../common/teams/TeamsDropdown";

export interface ColumnDefProps {
  departmentId: string;
  agentsFilterTeamId: string;
}

export interface ColumnDef {
  header: string;
  column: (key: string) => ReactElement<Column>;
}

export const columns = ({ departmentId, agentsFilterTeamId }: ColumnDefProps): ColumnDef[] => [
  {
    header: "Termen",
    column: (key: string) => (
      <Column
        key={key}
        style={fixedWidthColumnStyle(150)}
        header="Termen"
        field="slaPercent"
        body={(rowData: Ticket) => (
          <SlaProgressBar slaPercent={rowData.slaPercent} slaEndDatePretty={rowData.slaEndDatePretty}></SlaProgressBar>
        )}
      ></Column>
    ),
  },
  {
    header: "Prioritate",
    column: (key: string) => (
      <Column
        key={key}
        style={fixedWidthColumnStyle(150)}
        className="text-center"
        header="Prioritate"
        field="urgency"
        filter
        showFilterMatchModes={false}
        showFilterMenu={false}
        filterElement={(options: ColumnFilterElementTemplateOptions) => (
          <UrgencyDropdown showClear value={options.value} onChange={(e) => options.filterApplyCallback(e)} />
        )}
        body={(rowData: Ticket) => <UrgencyTag value={rowData.urgency}></UrgencyTag>}
      ></Column>
    ),
  },
  {
    header: "Status",
    column: (key: string) => (
      <Column
        key={key}
        style={fixedWidthColumnStyle(150)}
        className="text-center"
        header="Status"
        field="status"
        filter
        showFilterMatchModes={false}
        showFilterMenu={false}
        filterElement={(options: ColumnFilterElementTemplateOptions) => (
          <TicketStatusMultiSelect
            showClear
            departmentId={departmentId}
            value={options.value}
            onChange={(e) => options.filterApplyCallback(e)}
          />
        )}
        body={(rowData: Ticket) => <span>{rowData.statusType.name}</span>}
      ></Column>
    ),
  },
  {
    header: "Id",
    column: (key: string) => (
      <Column
        key={key}
        style={fixedWidthColumnStyle(100)}
        className="text-center"
        header="Id"
        field="prettyId"
        filter
        showFilterMatchModes={false}
        showFilterMenu={false}
      ></Column>
    ),
  },
  {
    header: "Titlu",
    column: (key: string) => (
      <Column
        key={key}
        style={{ minWidth: "200px" }}
        header="Titlu"
        field="subject"
        body={(rowData: Ticket) => (
          <div className="flex flex-column gap-2">
            <span className="font-semibold">{rowData.subject}</span>
            <span>{rowData.category?.name}</span>
          </div>
        )}
      ></Column>
    ),
  },
  {
    header: "Locatie",
    column: (key: string) => (
      <Column
        key={key}
        style={fixedWidthColumnStyle(135)}
        header="Locatie"
        field="location"
        body={(rowData: Ticket) => (
          <div className="flex">
            <span className="text-ellipsis" title={rowData.location}>
              {rowData.location}
            </span>
          </div>
        )}
      ></Column>
    ),
  },
  {
    header: "Grupa",
    column: (key: string) => (
      <Column
        key={key}
        style={fixedWidthColumnStyle(200)}
        className="text-center"
        header="Grupa"
        field="teamId"
        filter
        showFilterMatchModes={false}
        showFilterMenu={false}
        filterElement={(options: ColumnFilterElementTemplateOptions) => (
          <TeamsDropdown showClear departmentId={departmentId} value={options.value} onChange={(e) => options.filterApplyCallback(e)} />
        )}
        body={(rowData: Ticket) => (
          <div className="flex">
            <span className="text-ellipsis" title={rowData.team?.name}>
              {rowData.team?.name}
            </span>
          </div>
        )}
      ></Column>
    ),
  },
  {
    header: "Agent",
    column: (key: string) => (
      <Column
        key={key}
        style={fixedWidthColumnStyle(200)}
        className="text-center"
        header="Agent"
        field="agentId"
        filter
        showFilterMatchModes={false}
        showFilterMenu={false}
        filterElement={(options: ColumnFilterElementTemplateOptions) => (
          <UsersDropdown
            showClear
            teamIds={agentsFilterTeamId ? [agentsFilterTeamId] : undefined}
            role={UserRoleEnum.Agent}
            value={options.value}
            onChange={(e) => options.filterApplyCallback(e)}
          />
        )}
        body={(rowData: Ticket) => (
          <div className="flex">
            <span className="text-ellipsis" title={rowData.agent?.displayName}>
              {rowData.agent?.firstName} {rowData.agent?.lastName}
            </span>
          </div>
        )}
      ></Column>
    ),
  },
  {
    header: "Utilizator",
    column: (key: string) => (
      <Column
        key={key}
        style={fixedWidthColumnStyle(200)}
        header="Utilizator"
        field="ownerId"
        body={(rowData: Ticket) => (
          <div className="flex">
            <span className="text-ellipsis" title={rowData.owner?.displayName}>
              {rowData.owner?.firstName} {rowData.owner?.lastName}
            </span>
          </div>
        )}
      ></Column>
    ),
  },
  {
    header: "Data crearii",
    column: (key: string) => <Column key={key} style={fixedWidthColumnStyle(135)} header="Data crearii" field="createdPretty"></Column>,
  },
];

export const persistVisibleColumns = (visibleColumns: ColumnDef[]) => {
  localStorage.setItem(LS_AGENT_TICKETS_PAGE_VISIBLE_COLUMNS, JSON.stringify(visibleColumns.map((col) => col.header)));
};

export const fetchVisibleColumns = (columnsProps: ColumnDefProps, bypassStorage?: boolean) => {
  if (bypassStorage) {
    return columns(columnsProps);
  }

  const savedVisibleColumns: string[] = JSON.parse(localStorage.getItem(LS_AGENT_TICKETS_PAGE_VISIBLE_COLUMNS) || "[]");
  let result: ColumnDef[] = [];
  savedVisibleColumns.forEach((header) => {
    const found = columns(columnsProps).find((c) => c.header === header);
    if (found) {
      result.push(found);
    }
  });
  return result.length ? result : columns(columnsProps);
};

export const clearPersistedVisibleColumns = () => {
  localStorage.removeItem(LS_AGENT_TICKETS_PAGE_VISIBLE_COLUMNS);
};

export const persistFilters = (filters: any) => {
  localStorage.setItem(LS_AGENT_TICKETS_FILTERS, JSON.stringify(filters));
};

export const fetchFilters = (teamId?: string, bypassStorage?: boolean) => {
  const defaultFilters = {
    prettyId: { value: null, matchMode: FilterMatchMode.EQUALS },
    urgency: { value: null, matchMode: FilterMatchMode.EQUALS },
    status: { value: inWorkStatusesIds, matchMode: FilterMatchMode.EQUALS },
    teamId: { value: teamId, matchMode: FilterMatchMode.EQUALS },
    agentId: { value: null, matchMode: FilterMatchMode.EQUALS },
  };
  if (bypassStorage) {
    return defaultFilters;
  }

  const filters = localStorage.getItem(LS_AGENT_TICKETS_FILTERS);
  return JSON.parse(filters || JSON.stringify(defaultFilters));
};

export const clearPersistedFilters = () => {
  localStorage.removeItem(LS_AGENT_TICKETS_FILTERS);
};
