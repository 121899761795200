import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { confirmDialog } from "primereact/confirmdialog";

import { NonWorkingDay } from "../../common/nonWorkingDays/NonWorkingDay";
import NoResultsFound from "../../../common/components/noResultsFound/NoResultsFound";
import { convertDateToDateString } from "../../../common/utils/dateHelper";
import { createNonWorkingDay, deleteNonWorkingDay, fetchNonWorkingDays } from "../../common/nonWorkingDays/nonWorkingDayApi";

interface NonWorkingDaysTableProps {
  departmentId: string;
}

export default function NonWorkingDaysTable({ departmentId }: NonWorkingDaysTableProps) {
  const [nonWorkingDays, setNonWorkingDays] = useState<NonWorkingDay[]>([]);
  const [nonWorkingDay, setNonWorkingDay] = useState<Date | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    doFetchNonWorkingDays();
  }, [departmentId]);

  const doFetchNonWorkingDays = async () => {
    if (departmentId) {
      const nonWorkingDays = await fetchNonWorkingDays(departmentId);
      setNonWorkingDays(nonWorkingDays);
    }
  };

  const onAddNonWorkingDay = async () => {
    if (!nonWorkingDay) {
      return;
    }
    if (nonWorkingDays.some((day) => day.id === convertDateToDateString(nonWorkingDay))) {
      toast.error(`Ziua de ${convertDateToDateString(nonWorkingDay)} a fost deja înregistrată ca zi liberă.`);
      return;
    }

    const newNonWorkingDay: NonWorkingDay = {
      id: convertDateToDateString(nonWorkingDay)!,
      departmentId,
    };
    setIsLoading(true);
    await createNonWorkingDay(newNonWorkingDay);
    await doFetchNonWorkingDays();
    setIsLoading(false);
    setNonWorkingDay(undefined);
  };

  const onDelete = (nonWorkingDay: NonWorkingDay) => {
    confirmDialog({
      header: "Confirmare stergere",
      icon: "pi pi-exclamation-triangle",
      message: (
        <div className="text-xl">
          Esti sigur ca vrei sa stergi ziua libera de <span className="font-bold">{nonWorkingDay.id}</span>?
        </div>
      ),
      acceptLabel: "Da",
      rejectLabel: "Nu",
      accept: async () => {
        await deleteNonWorkingDay(nonWorkingDay);
        await doFetchNonWorkingDays();
      },
    });
  };

  return (
    <>
      <div className="flex gap-2 mb-4">
        <Calendar
          className="flex-1"
          dateFormat="dd/mm/yy"
          showIcon
          pt={{
            dropdownButton: {
              root: { className: "bg-primary-500" },
            },
          }}
          value={nonWorkingDay}
          onChange={(e) => setNonWorkingDay(e.value ?? undefined)}
          placeholder="Selecteaza data"
        />
        <Button label="Adauga" icon="pi pi-plus" loading={isLoading} disabled={isLoading} onClick={() => onAddNonWorkingDay()} />
      </div>
      <DataTable size="small" value={nonWorkingDays} emptyMessage={<NoResultsFound />}>
        <Column className="w-full" field="id" header="Ziua"></Column>
        <Column
          header="Actiune"
          body={(rowData: NonWorkingDay) => (
            <Button size="small" label="Sterge" severity="danger" icon="pi pi-trash" onClick={() => onDelete(rowData)} />
          )}
        ></Column>
      </DataTable>
    </>
  );
}
