import { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Chip } from "primereact/chip";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { FileUpload } from "primereact/fileupload";
import { InputSwitch } from "primereact/inputswitch";
import { Checkbox } from "primereact/checkbox";

import { Department } from "../../common/departments/Department";
import { uploadIcon } from "../../common/departments/departmentsSlice";
import FormGroup from "../../../common/components/form/FormGroup";
import IconPicker from "../../../common/components/iconPicker/IconPicker";
import { useAppDispatch } from "../../../common/hooks/useStore";
import { FileUploadResult } from "../../../common/types/FileUploadResult";
import TeamsDropdown from "../../common/teams/TeamsDropdown";

interface DepartmentFormProps {
  department?: Department;
  onSubmit: (department: Department) => Promise<void>;
}

export default function DepartmentForm(props: DepartmentFormProps) {
  const dispatch = useAppDispatch();
  const {
    register,
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<Department>({ defaultValues: props.department });
  const iconFileUploadRef = useRef<FileUpload>(null);
  const [iconFileName, setIconFileName] = useState<string | undefined>(props.department?.fileName);
  const [isLoading, setIsLoading] = useState(false);

  const onUpload = async (event: any) => {
    const result = await dispatch(uploadIcon(event.files[0]));
    const fileName = (result?.payload as FileUploadResult)?.fileName;
    setIconFileName(fileName ? event.files[0].name : undefined);
    setValue("fileName", fileName);
    iconFileUploadRef.current?.clear();
  };

  const onSubmit = async (data: Department) => {
    setIsLoading(true);
    await props.onSubmit(data);
    setIsLoading(false);
  };

  const onRemoveFile = () => {
    setIconFileName(undefined);
    setValue("fileName", "");
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup inline label="Activ">
        <Controller
          name="isActive"
          control={control}
          render={({ field }) => <InputSwitch checked={field.value} onChange={(e) => field.onChange(e.value)} />}
        ></Controller>
      </FormGroup>
      <FormGroup label="Nume *" errorMessage={errors.name?.message}>
        <InputText {...register("name", { required: "Numele este obligatoriu" })} />
      </FormGroup>
      {props.department && (
        <FormGroup label="Grupa implicita">
          <Controller
            name="defaultTeamId"
            control={control}
            render={({ field }) => (
              <TeamsDropdown
                departmentId={props.department!.id}
                value={field.value}
                onChange={(selectedValue) => field.onChange(selectedValue)}
              />
            )}
          />
        </FormGroup>
      )}
      <FormGroup checkbox label="Cost center">
        <Controller
          name="costCentersEnabled"
          control={control}
          render={({ field }) => <Checkbox checked={field.value} onChange={(e) => field.onChange(e.checked)} />}
        ></Controller>
      </FormGroup>
      <FormGroup checkbox label="Note interne">
        <Controller
          name="internalNotesEnabled"
          control={control}
          render={({ field }) => <Checkbox checked={field.value} onChange={(e) => field.onChange(e.checked)} />}
        ></Controller>
      </FormGroup>
      <FormGroup checkbox label="Agent necesar">
        <Controller
          name="agentRequiredOnTicketCreation"
          control={control}
          render={({ field }) => <Checkbox checked={field.value} onChange={(e) => field.onChange(e.checked)} />}
        ></Controller>
      </FormGroup>
      <FormGroup checkbox label="Trimite notificare catre email secundar">
        <Controller
          name="sendNotificationsToSecondaryEmail"
          control={control}
          render={({ field }) => <Checkbox checked={field.value} onChange={(e) => field.onChange(e.checked)} />}
        ></Controller>
      </FormGroup>
      <FormGroup label="Icoana">
        <Controller
          name="icon"
          control={control}
          render={({ field }) => <IconPicker value={field.value} onChange={(e) => field.onChange(e)}></IconPicker>}
        ></Controller>
      </FormGroup>
      <FormGroup label="Imagine">
        <Controller
          name="fileName"
          control={control}
          render={() => (
            <div className="flex flex-wrap align-items-center gap-2">
              <FileUpload
                auto
                customUpload
                mode="basic"
                accept="image/*"
                chooseLabel="Adauga fisier"
                ref={iconFileUploadRef}
                uploadHandler={onUpload}
                maxFileSize={10 * 1024 * 1024}
                invalidFileSizeMessageDetail="Fisierul depaseste dimensiunea maxima permisa de 1MB"
              />
              {iconFileName && <Chip label={iconFileName} icon="pi pi-file" removable onRemove={() => onRemoveFile()} />}
            </div>
          )}
        ></Controller>
      </FormGroup>
      <Button type="submit" className="block ml-auto" label="Salveaza" icon="pi pi-save" loading={isLoading} disabled={isLoading}></Button>
    </form>
  );
}
