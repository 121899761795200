import axios from "axios";
import { toast } from "react-toastify";

import { WorkingHours } from "./WorkingHours";
import { convertUTCTimeStringToTimeString, convertTimeStringToUTCTimeString } from "../../../common/utils/dateHelper";

const WORKING_HOURS_API_URL = `${process.env.REACT_APP_API_BASE_URL}/workingHours`;

export const fetchWorkingHours = async (departmentId: string) => {
  try {
    const response = await axios.get(`${WORKING_HOURS_API_URL}/department/${departmentId}`);
    const workingHours: WorkingHours[] = response.data.map((workingHour: any) => ({
      id: `${workingHour.id}`,
      departmentId: `${workingHour.departmentId}`,
      start: convertUTCTimeStringToTimeString(workingHour.start),
      end: convertUTCTimeStringToTimeString(workingHour.end),
      isWorkingDay: workingHour.isWorkingDay,
    }));
    return workingHours;
  } catch (err) {
    return [];
  }
};

export const updateWorkingHour = async (updatedWorkingHour: WorkingHours) => {
  const payload = {
    ...updatedWorkingHour,
    start: convertTimeStringToUTCTimeString(updatedWorkingHour.start),
    end: convertTimeStringToUTCTimeString(updatedWorkingHour.end),
    id: parseInt(updatedWorkingHour.id),
    departmentId: parseInt(updatedWorkingHour.departmentId),
  };
  await axios.put(WORKING_HOURS_API_URL, payload);
  toast.success("Ziua de lucru a fost actualizata cu succes!");
};
