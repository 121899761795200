import { toast } from "react-toastify";
import axios from "axios";

import { QuickSearchUsersFilter, SearchUsersFilter, User } from "./User";
import { emptyPaginationDetails, mapApiPaginationDetails, mapToApiPagination } from "../../../common/api/paginationHelper";
import { addUserToTeams, TEAMS_API_URL } from "../teams/teamsApi";
import { UserRoleEnum } from "../userRoles/UserRole";

const USERS_API_URL = `${process.env.REACT_APP_API_BASE_URL}/users`;

export const searchUsers = async (filter: SearchUsersFilter) => {
  try {
    const payload = {
      teamId: filter.teamId && parseInt(filter.teamId),
      firstName: filter.firstName,
      lastName: filter.lastName,
      emailAddress: filter.emailAddress,
      role: filter.role && parseInt(filter.role),
      ...mapToApiPagination(filter),
    };
    const response = await axios.post(`${USERS_API_URL}/search`, payload);
    const pagination = mapApiPaginationDetails(response.data);
    const users: User[] = response.data.items.map(mapApiUser);
    return {
      pagination,
      users,
    };
  } catch (err) {
    return {
      pagination: emptyPaginationDetails,
      users: [],
    };
  }
};

export const quickSearchUsers = async (filter: QuickSearchUsersFilter) => {
  try {
    const payload = {
      teamIds: (filter.teamIds || []).map((teamId) => parseInt(teamId)).filter((teamId) => !isNaN(teamId)),
      role: filter.role && parseInt(filter.role),
      term: filter.searchTerm || "",
    };
    const response = await axios.post(`${USERS_API_URL}/quicksearch`, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    const users: User[] = response.data.map(mapApiUser);
    return users;
  } catch (error) {
    return [];
  }
};

export const fetchTeamUsers = async (teamId: string) => {
  const response = await axios.get(`${TEAMS_API_URL}/users/${teamId}`);
  const users: User[] = response.data.map(mapApiUser);
  return users;
};

export const fetchUser = async (userId: string) => {
  const response = await axios.get(`${USERS_API_URL}/${userId}`);
  return mapApiUser(response.data);
};

export const updateUser = async (user: User) => {
  const payload = {
    id: user.id,
    departmentId: user.role === UserRoleEnum.Agent ? parseInt(user.departmentId) : null,
    role: parseInt(user.role),
  };
  await axios.put(USERS_API_URL, payload);
  if (user.role === UserRoleEnum.Agent) {
    await addUserToTeams(user.id, user.teamIds);
  }
  toast.success("Utilizatorul a fost modificat cu succes!");
};

export const syncUsers = async () => {
  await axios.post(`${USERS_API_URL}/sync`);
  toast.success("Utilizatorii se sincronizeaza...");
};

export const mapApiUser = (apiUser: any): User => ({
  id: `${apiUser.id}`,
  teamIds: (apiUser.teams || []).map((team: any) => `${team.teamId}`),
  departmentId: apiUser.departmentId && `${apiUser.departmentId}`,
  role: `${apiUser.role}`,
  username: apiUser.username,
  firstName: apiUser.firstName,
  lastName: apiUser.lastName,
  emailAddress: apiUser.emailAddress,
  displayName: apiUser.username?.toLowerCase() || apiUser.emailAddress?.toLowerCase() || `${apiUser.firstName} ${apiUser.lastName}`,
  outOfOfficeEnabled: apiUser.outOfOfficeEnabled,
  outOfOfficeStart: apiUser.outOfOfficeStart,
  outOfOfficeEnd: apiUser.outOfOfficeEnd,
  outOfOfficeReplacementId: apiUser.outOfOfficeReplacementId,

  secondaryEmailAddress: apiUser.secondaryEmailAddress,
  managerId: apiUser.managerId,
  phoneNumber: apiUser.phoneNumber,
  mobileNumber: apiUser.mobileNumber,
  notes: apiUser.notes,
  company: apiUser.company,
  location: apiUser.location,
  postalCode: apiUser.postalCode,
});
