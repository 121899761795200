import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";
import { Divider } from "primereact/divider";
import { toast } from "react-toastify";

import FormGroup from "../../../common/components/form/FormGroup";
import UrgencyDropdown from "../../common/urgencies/UrgenciesDropdown";
import UsersDropdown from "../../common/users/UsersDropdown";
import TicketStatusDropdown from "../../common/ticketStatuses/TicketStatusDropdown";
import { PropsWithClassName } from "../../../common/types/PropsWithClassName";
import { changeAgent, changeOwner, changeStatus, changeTeam, changeCategory, changeUrgency } from "../../common/tickets/ticketsApi";
import { selectAuthUser } from "../../auth/authSlice";
import Label from "../../../common/components/form/Label";
import TeamsDropdown from "../../common/teams/TeamsDropdown";
import { UserRoleEnum } from "../../common/userRoles/UserRole";
import TicketTypeDropdown from "../../common/ticketType/TicketTypeDropdown";
import { TicketType } from "../../common/ticketType/TicketType";
import CategoriesDropdown from "../../common/categories/CategoriesDropdown";

interface TicketActionsProps extends PropsWithClassName {
  ticketIds: string[];
  ticketStatus?: string;
  teamId?: string;
  agentId?: string;
  ownerId?: string;
  urgency?: string;
  ticketType?: TicketType;
  departmentId?: string;
  categoryId?: string;
  disabled?: boolean;
  onActionFinished?: () => void;
}

export default function TicketActions(props: TicketActionsProps) {
  const authUser = useSelector(selectAuthUser);
  const [selectedTicketStatus, setSelectedTicketStatus] = useState(props.ticketStatus);
  const [selectedTeamId, setSelectedTeamId] = useState(props.teamId);
  const [selectedAgentId, setSelectedAgentId] = useState(props.agentId);
  const [selectedOwnerId, setSelectedOwnerId] = useState(props.ownerId);
  const [selectedUrgency, setSelectedUrgency] = useState(props.urgency);
  const [selectedTicketType, setSelectedTicketType] = useState(props.ticketType);
  const [selectedCategoryId, setSelectedCategoryId] = useState(props.categoryId);

  useEffect(() => {
    setSelectedTicketStatus(props.ticketStatus);
  }, [props.ticketStatus]);

  const onTicketStatusChange = async (ticketStatusId?: string) => {
    if (!selectedAgentId) {
      toast.error("Selecteaza un agent inainte de a schimba statusul");
      return;
    }

    openConfirmDialog("Schimbare status", "Esti sigur ca vrei sa schimbi statusul?", async () => {
      setSelectedTicketStatus(ticketStatusId);
      if (ticketStatusId != null) {
        await changeStatus(props.ticketIds, ticketStatusId);
      }
      props.onActionFinished?.();
    });
  };

  const onOwnerIdChange = async (ownerId?: string) => {
    openConfirmDialog("Schimbare utilizator", "Esti sigur ca vrei sa schimbi utilizatorul?", async () => {
      setSelectedOwnerId(ownerId);
      if (ownerId) {
        await changeOwner(props.ticketIds, ownerId);
      }
      props.onActionFinished?.();
    });
  };

  const onTeamIdChange = async (teamId?: string) => {
    openConfirmDialog("Schimbare grupa", "Esti sigur ca vrei sa schimbi grupa?", async () => {
      setSelectedTeamId(teamId);
      await changeTeam(props.ticketIds, teamId);
      props.onActionFinished?.();
    });
  };

  const onAgentIdChange = async (agentId?: string) => {
    openConfirmDialog("Schimbare agent", "Esti sigur ca vrei sa schimbi agentul?", async () => {
      setSelectedAgentId(agentId);
      await changeAgent(props.ticketIds, agentId);
      props.onActionFinished?.();
    });
  };

  const onUrgencyChange = async (urgency?: string) => {
    openConfirmDialog("Schimbare prioritate", "Esti sigur ca vrei sa schimbi prioritatea?", async () => {
      setSelectedUrgency(urgency);
      if (urgency != null) {
        await changeUrgency(props.ticketIds, urgency);
      }
      props.onActionFinished?.();
    });
  };

  const onCategoryChange = async (categoryId?: string) => {
    if (selectedTicketType == null || categoryId == null) {
      return;
    }

    openConfirmDialog("Schimbare categorie", "Esti sigur ca vrei sa schimbi categoria / tipul tichetului?", async () => {
      setSelectedCategoryId(categoryId);
      await changeCategory(props.ticketIds, selectedTicketType, categoryId!);
      props.onActionFinished?.();
    });
  };

  const openConfirmDialog = async (header: string, text: string, onAccept: () => {}, onReject?: () => void) => {
    if (props.disabled) {
      return;
    }

    confirmDialog({
      header,
      icon: "pi pi-exclamation-triangle",
      message: <div className="text-xl">{text}</div>,
      acceptLabel: "Da",
      rejectLabel: "Nu",
      accept: () => onAccept(),
      reject: () => onReject?.(),
    });
  };

  return (
    <div className={`${props.className} ${props.disabled ? "opacity-50 pointer-events-none" : ""} flex flex-column`}>
      {props.departmentId != null && (
        <>
          <FormGroup label="Tip">
            <TicketTypeDropdown value={selectedTicketType} onChange={(ticketType) => setSelectedTicketType(ticketType)} />
          </FormGroup>
          {selectedTicketType != null && (
            <FormGroup label="Categorie">
              <CategoriesDropdown
                column
                ticketType={selectedTicketType}
                departmentId={props.departmentId}
                value={selectedCategoryId}
                onChange={onCategoryChange}
              />
            </FormGroup>
          )}
          <Divider className="mt-0" />
        </>
      )}
      <FormGroup label="Utilizator">
        <UsersDropdown value={selectedOwnerId} onChange={onOwnerIdChange}></UsersDropdown>
      </FormGroup>
      {props.departmentId != null && (
        <FormGroup label="Grupa">
          <TeamsDropdown departmentId={props.departmentId} value={selectedTeamId} onChange={onTeamIdChange}></TeamsDropdown>
        </FormGroup>
      )}
      <FormGroup>
        <div className="flex align-items-center justify-content-between gap-2">
          <Label>Agent</Label>
          <Button size="small" label="Atribuie mie" text onClick={() => onAgentIdChange(authUser.id)} />
        </div>
        <UsersDropdown
          placeholder="Selecteaza agent"
          teamIds={selectedTeamId ? [selectedTeamId] : undefined}
          role={UserRoleEnum.Agent}
          value={selectedAgentId}
          onChange={onAgentIdChange}
        ></UsersDropdown>
      </FormGroup>
      <Divider className="mt-0" />
      {props.departmentId != null && (
        <FormGroup label="Status">
          <TicketStatusDropdown
            departmentId={props.departmentId}
            value={selectedTicketStatus}
            onChange={onTicketStatusChange}
          ></TicketStatusDropdown>
        </FormGroup>
      )}
      {selectedTicketType === TicketType.Incident && (
        <FormGroup label="Prioritate">
          <UrgencyDropdown value={selectedUrgency} onChange={onUrgencyChange}></UrgencyDropdown>
        </FormGroup>
      )}
    </div>
  );
}
